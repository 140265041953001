.engine-header {
  width: 100%;
  height: 0px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.engine-header-img {
  height: 100%;
}

.engine-formgroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.engine-inputform {
  min-width: 80%;
  max-width: 80%;
  min-height: 35px !important;
  max-height: 200px !important;
}

.engine-form-button-section {
  display: flex;
}

.engine-form-button {
  align-items: flex-end;
  margin: 0 !important;
}

@media screen and (max-width: 570px) {
  .engine-inputform {
    min-width: 70%;
    max-width: 60%;
    min-height: 35px !important;
    max-height: 200px !important;
  }
}