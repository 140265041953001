.edit-app-wrapper {
  height: 97%;
  width: 100%;
  padding: 0rem 2rem 2rem 2rem;
  overflow: hidden;
}

.edit-app-container {
  background-color: #efefef;
  height: 100%;
  width: 100%;
  padding: 25px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.edit-app-container::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 900px) {
  .edit-app-wrapper {
    height: 97%;
    padding: 0 1rem;
  }

  .edit-app-container {
    padding: .5rem;
  }
}