.p-card {
  background-color: rgba(255, 255, 255, 0.98) !important;
  border-radius: 10px !important;
}

.authentication-route {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  min-height: 100svh;
  min-width: 100vw;
  background: linear-gradient(125deg, rgba(34, 56, 77, 1) 40%, rgba(62, 103, 142, 1) 130%);
}

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 25% 75%;
  width: 100%;
  height: 100%;
  filter: blur(7px);
}

.action-section {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 35px;
  width: auto;
}

.version-btn {
  position: absolute;
  bottom: 50px;
  right: 50px;
  color: whitesmoke;
}

@media screen and (max-width: 900px) {
  .authentication-route {
    max-width: 100%;
  }
  .version-btn {
    position: absolute;
    bottom: 25px;
    right: 35px;
  }
  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    width: 100%;
    height: 100%;
    filter: blur(7px);
  }
}

@media screen and (max-width: 570px) {
  .authentication-route {
    max-width: 100%;
  }
}
