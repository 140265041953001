#editAppCard {
  width: 100%;
}

.appMainData {
  padding: 15px;
  margin-top: 15px;
  margin-left: 25px;
}

.edit-app-formgroup {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  gap: 0px;
}

.edit-app-formgroup label {
  font-size: 18px;
  font-weight: 500;
}

.edit-app-formgroup button {
  width: 50px;
  height: 50px;
  justify-content: center;
  background-color: #f8f9fa;
  border: none;
  margin-left: 0.5rem;
}

.edit-app-formgroup img {
  width: 50px;
}

.edit-app-formgroup img {
  width: 50px;
}

.app-calendar {
  margin-left: 8%;
}

.app-calendar div {
  margin-top: 0.3rem;
}

.edit-app-formgroup:last-child {
  margin-top: 1.5rem;
}

.edit-app-formgroup:last-child label {
  margin-bottom: 0.5rem;
}

.app-analytics {
  margin-left: 6%;
}

.edit-wrapper {
  margin: 0 20%;
  height: auto !important;
  padding-bottom: 40px;
}



@media screen and (max-width: 570px) {
  .edit-dropdown {
    max-width: 195px !important;
  }

  .edit-wrapper {
    margin: 0;
  }
}