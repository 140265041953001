#appName {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: .7rem 0;
  gap: 10px;
}

#appIcon {
  width: 50px;
  width: 50px;
}

.appname-container {
  display: inline-block;
  color: white;
}

.app-name__icon {
  width: 30px !important;
}

.app-name__icon2 {
  width: 90px !important;
}