#editAppCard {
  width: 100% !important;
  margin-bottom: 20px !important;
}

#appMainData {
  display: flex;
}

#possibleDefaulters {
  margin-left: 0%;
}

#lblDefaulters {
  margin-top: 5px;
}

#defaulters {
  margin-left: 20%;
}

#timeData {
  display: flex;
  flex-direction: column;
  background-color: #d1d3d8;
  z-index: 1;
  text-align: center;
  margin-left: auto;
  padding: 15px;
}

#timeData label {
  font-weight: 500;
  margin-top: 15px;
}

#timeData h4 {
  margin-top: 0%;
  margin-bottom: 0%;
}

.tag-title {
  margin-bottom: 0 !important;
  margin-right: 20px !important;
}

.tag-value {
  margin-bottom: 0 !important;
}

.edit-row {
  display: flex;
  justify-content: space-around;
}