.yesno-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(69, 69, 69, 0.15);
  border-radius: 20px;
  margin-top: -0px;
}
.yesno-txt {
  font-size: 1.4375em !important;
  line-height: 35px;
  display: flex;
  width: 100%;
  max-width: 400px;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  color: #6f7a88;
  margin: 0;
  padding: 40px 30px 20px 30px;
}

.yesno-txt2 {
  font-size: 1em !important;
  line-height: 35px;
  display: flex;
  width: 100%;
  max-width: 700px;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  color: #6f7a88;
  margin: 0;
  padding: 40px 30px 30px 30px;
}

.flex-row3 {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0px 20px 20px 20px;
}

.mb-primary {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 140px !important;
  color: white !important;
  font-size: 1em !important;
  padding: 22px 16px !important;
}

.mb-secondary {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 140px !important;
  color: #6f7a88 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 1em !important;
  padding: 22px 16px !important;
  border: 1px solid #6f7a88 !important;
}

.mb-secondary:hover {
  color: white !important;
}

.mb-cancel {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 140px !important;
  color: #be1616 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 1em !important;
  padding: 22px 16px !important;
  border: 1px solid #be1616 !important;
}

.mb-cancel:hover {
  color: white !important;
}

@media screen and (max-width: 570px) {
  .yesno-txt {
    max-width: 330px;
  }

  .yesno-txt2 {
    font-size: 1em !important;
    line-height: 35px;
    display: flex;
    max-width: 85vw;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    color: #6f7a88;
    margin: 0;
    padding: 40px 30px 20px 30px;
  }

  .mb-cancel:hover {
    background-color: transparent !important;
    color: #be1616 !important;
  }
}

