#header {
  height: 100% !important;
  display: flex;
  justify-content: center;
}

#header .pro-sidebar {
  height: 100vh;
  color: #FFF;
  max-height: 1180px !important;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
  align-self: center;
  transition: width .8s;
}

.navbar-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}


#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar.expanded {
  width: 0px !important;
}

#header .pro-sidebar-inner {
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  background-color: #22384D;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#header .pro-sidebar-inner .pro-sidebar-layout::-webkit-scrollbar {
  display: none;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  width: 100%;
  padding: 0 15%;
  font-weight: 500;
  min-width: 220px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  font-weight: 500;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  margin: 0;
  border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-color: #f04;
}

.pro-menu-item:hover {
  background-color: #f04 !important;
}

.active {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 3.5px solid white;
}

#header .logo {
  padding: 20px;
}

#header .pro-icon-wrapper {
  background-color: transparent;
}

.menu-opt {
  color: #fff !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  margin-left: 2px !important;
  font-size: 12px !important;
  border: transparent !important;
  height: 13px;
}

.footer-menu-opts {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px 0 0 0;
  padding: 0 15%;
}

.pro-inner-item {
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  padding: .5rem 0 !important;
}

.pro-sidebar .pro-menu {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.menu-opt {
  font-size: .8rem;
  white-space: normal;
}

.menu-opt:hover {
  background-color: #f04 !important;
}

.nav-icon {
  width: 30px;
  height: 30px;
}

.nav-logo {
  width: 48px;
  height: 35px;
  transition: width 2s, height 2s;
}

@media screen and (max-width: 900px) {

  html {
    overflow: hidden;
  }

  .menu-opt {
    margin-left: 4px !important;
  }
}