.app-form-icon-section {
  display: flex;
  font-weight: 600;
  width: 100%;
  font-size: 22px;
  align-items: center;
  justify-content: center;
}

.app-form-section {
  padding: 2%;
}

.app-formGroup {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.app-form-inputText {
  width: 85%;
  margin-left: auto !important;
}

.app-form-button {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.app-input-required-text {
  margin: 0 0 0 15% !important;
}