.loginFormGroup {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.loginLabel {
  margin-bottom: 4px;
  margin-left: 0px;
  z-index: 100;
  width: 70px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 0.8em;
  font-weight: 300;
  border-radius: 8px;
  color: white;
}
.loginFormGroup {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
}

.loginFormGroup:first-child {
  margin-top: 0.1rem;
}

.loginInputText {

  width: 220px !important;
  padding: 7px 20px 7px 40px !important;
  border: none !important;
  background-color: white !important;
  border-radius: 8px !important;
}

.hint {
  margin-right: 3px;
  margin-top: -5px !important;
}

.submit-btn {
  background-color: #3e678e !important;
  margin: 2rem 0 0 0 !important;
  width: 95% !important;
  height: 40px !important;
  font-size: 0.9em !important;
}

.form-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  min-width: 300px !important;
  padding: 30px;
  background-color: #192d4094;
  border-radius: 10px !important;
  z-index: 10000000;
}

@media screen and (max-width: 900px) {
  .loginFormGroup:first-child {
    margin-top: 0;
  }
}

.hint {
  margin-right: 5px;
}

.form-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: height 5s;
  min-width: 80vw;
}

@media screen and (max-width: 570px) {
  .submit-btn {
    margin: 1.7rem 0 0 0 !important;
    height: 40px !important;
    width: 100% !important;
  }
  .loginInputText {
    width: 100% !important;
    border: none !important;
    background-color: white !important;
    border-radius: 8px !important;
  }
}
