.modeler-wrapper {
  width: 100%;
  height: 100%;
  padding: 2rem 0rem 0 0rem;
}

.modeler-container {
  height: 100vh;
  overflow: hidden;
  border-radius: 5px;
}

.modeler-header {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 2rem;
  height: 60px;
  width: 100%;
  background-color: #7f1717cc;
}

.table-wrapper {
  height: 100%;
  height: 100%;
}

.edit-button {
  background-color: #22384d !important;
  margin-left: 1rem !important;
  border: 1px solid #22384d !important;
}

.edit-button:hover {
  color: #ffffff;
  border-color: #22384d !important;
}

.actionButton {
  height: 33px !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

.actionButton:hover {
  background-color: #f04 !important;
}

.create-button {
  padding-top: 17px !important;
  padding-left: 0px !important;
  width: 135% !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: rgba(246, 245, 245) !important;
}

.filters-modeler {
  min-width: 265px;
  margin: 0px !important;
}

.pi-plus {
  color: rgba(246, 245, 245) !important;
  margin-right: 20px !important;
}

.table-modeler-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(246, 245, 245);
}

.modeler-table {
  height: calc(100vh - 120px);
  width: 10vw;
  padding: 0rem 0rem 0 2rem;
}

.modeler-message {
  font-size: 20px;
  color: black;
  text-align: center;
}

.p-datatable .p-datatable-tbody > tr:nth-child(odd) {
  background-color: #efefef !important;
}

.modeler-filter {
  background-color: rgba(97, 93, 93, 0.8) !important;
}

@media screen and (max-width: 900px) {
  .modeler-table {
    padding: 1rem 1rem 0.6rem 1rem;
    height: calc(100vh - 67px);
  }

  .modeler-wrapper {
    padding: 0;
  }

  .modeler-container {
    padding: 0;
  }

  .table-wrapper {
    margin: 0 1rem;
    padding: 1rem 0 1rem 0;
    overflow: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .table-wrapper::-webkit-scrollbar {
    display: none;
  }

  .button {
    width: 50%;
  }

  .modeler-header {
    width: 100%;
    border-radius: 0px;
    overflow: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .modeler-header::-webkit-scrollbar {
    display: none;
  }

  .sub-header {
    display: flex;
    width: 500px;
  }
}

@media screen and (max-width: 570px) {
  .modeler-filter {
    margin-left: 0px;
  }

  .filters-modeler {
    margin-left: 20px !important;
  }

  .create-button {
    padding-top: 7px !important;
    height: 40px !important;
    margin-bottom: 0px !important;
  }

  .modeler-message {
    font-size: 16px;
    margin: 0 !important;
  }

  .table-wrapper {
    margin: 0 1rem;
    padding: 1rem 0 1rem 0;
    overflow: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .table-wrapper::-webkit-scrollbar {
    display: none;
  }
}
