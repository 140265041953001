.not-found-main {
  height: 100dvh;
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  background-color: rgba(240, 248, 255, 0.856);
  border-radius: 20px;
  padding: 40px;
  margin: 20px;
  text-align: center;
}

.not-found-header {
  font-size: 1.1em !important;
  text-align: center !important;
  margin-top: 20px;
  max-width: 600px;
}

.not-found-card-img {
  height: 150px;
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 570px) {
  .not-found-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
  }
  .not-found-header {
    font-size: 1em !important;
    text-align: center !important;
    margin-top: 0px;
    margin-bottom: 0px;
    max-width: 80vw;
  }
  .not-found-card-img {
    height: 120px;
    width: 100%;
  }
}
