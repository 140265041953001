.details-case-wrapper {
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 20px;
}

.details-case-header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 6.3rem;
}

.details-case-header-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 30px;
  margin-right: 20px;
  gap: 10px;
  background-color: transparent;
}

.details-case-close {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.details-case-header-circle {
  height: 40px;
  width: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  color: black;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  margin: 1.2rem 0 0 1.2rem;
  box-shadow: 2px 2px 7px #0129398a !important;
}

.button-case-display {
  background-color: #2196f3 !important;
  height: 34px !important;
  margin-top: 2px !important;
}

.details-case-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
}

.details-case-hero {
  width: 100%;
  height: 100%;
  padding: 25px 25px;
  overflow: hidden;
  background: #d5d5d5;
  z-index: 1000;
}

.hero-hidden {
  height: 0px;
  transition: height 0.15s ease-out;
}

.hero-displayed {
  height: auto;
  transition: height 0.15s ease-in;
}

.details-case-template-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.details-case-template-title {
  font-weight: 500;
  margin-left: 20px;
}

.details-case-template-container {
  height: 100%;
  min-width: 100%;
  transition: width 2s;
  overflow: hidden;
}

.close {
  position: relative;
  box-shadow: none !important;
  height: 34px !important;
  width: 33px !important;
  cursor: pointer;
  color: rgb(255, 255, 255) !important;
  margin-top: 20px !important;
  margin-right: 10px !important;
  background-color: transparent !important;
}

.close:before,
.close:after {
  position: absolute;
  left: 16px;
  content: ' ';
  height: 20px !important;
  width: 2px !important;
  background-color: rgb(255, 255, 255);
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.button-case-action {
  height: 34px !important;
  width: 120px !important;
  color: #fff !important;
  margin-top: 20px !important;
  background-color: transparent !important;
  gap: 10px;
}

.saveData {
  background-color: #2196f3 !important;
}

.unLock {
  background-color: #f44336 !important;
}

.closed {
  background-color: #4caf50 !important;
}

.button-case-action2 {
  height: 34px !important;
  width: 150px;
  color: #fff !important;
  margin-top: 20px !important;
  background-color: #2196f3 !important;
}

.button-action-share {
  display: absolute;
  background: transparent;
  margin: 0px 0 -14px 2px !important;
  padding-top: 0 !important;
}

.details-case-th {
  color: rgb(255, 255, 255);
  font-weight: 500;
  width: 130px;
}

.details-case-th {
  text-align: left;
  padding: 5px;
}

.details-case-td {
  color: rgb(236, 228, 228);
  text-align: left;
  padding-left: 45px;
}

.details-case-td {
  display: flex;
  align-items: center;
}

.details-case-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}

.details-case-td:nth-child(even) {
  background-color: rgba(123, 123, 123, 0.315);
}

.setting-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 40px !important;
  height: 0px;
}

.fullscreen-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: none !important;
}

.fullscreen-btn-on {
  position: sticky;
  top: 0;
  right: 0;
  background-color: transparent !important;
  box-shadow: none !important;
}

.fullscreen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 300000000000 !important;
  background-color: rgba(236, 236, 231);
}

.goback-action {
  display: flex;
  justify-content: center !important;
  margin-top: 40px !important;
  margin-left: 10px !important;
  height: 40px;
  width: 40px;
  padding: 0 !important;
}

.arrow-left {
  height: 22px;
  width: 25px;
}

@media screen and (max-width: 1300px) {
  .details-case-template-container {
    transition: width 1s;
  }
}

@media screen and (max-width: 900px) {
  .fullscreen-btn {
    background-color: transparent !important;
    box-shadow: none !important;
    margin-right: -35px !important;
    margin-top: -15px !important;
    border-radius: none !important;
    border-top-left-radius: 0% !important;
    border-bottom-left-radius: 30% !important;
  }

  .button-case-display {
    margin-top: 15px !important;
  }

  .details-case-header {
    position: absolute;
    top: 0;
    left: 0px;
    height: 90px;
    display: flex;
    padding: 0 !important;
    margin-top: -17px;
    justify-content: flex-start;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .details-case-header-circle {
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    color: black;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    margin: 1.2rem 0 0 0;
  }

  .details-case-table {
    margin-left: 0;
  }

  .details-case-template-wrapper {
    padding: 1rem;
    overflow: hidden;
  }

  .details-case-header-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 5px;
    margin-right: 10px;
    margin-top: 7px;
    gap: 5px;
  }

  .button-case-action {
    width: 120px !important;
    color: rgb(255, 255, 255) !important;
    margin-top: 15px !important;
  }

  .button-case-action2 {
    width: 120px;
    color: rgb(255, 255, 255) !important;
    margin-top: 15px !important;
    font-size: 12px !important;
  }

  .button-action-share {
    padding-top: 8px !important;
    margin: 5px 0px 5px 1px !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .close {
    background-color: transparent !important;
    box-shadow: none !important;
    position: relative;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-top: 14px !important;
  }

  .close:before,
  .close:after {
    height: 25px;
  }

  .details-case-td {
    align-items: center;
  }

  .details-case-tr {
    display: flex;
    flex-direction: column;
  }

  .details-case-template-wrapper {
    margin: 0rem;
    padding: 0rem;
  }

  .details-case-template-container {
    padding: 0px;
  }

  .details-case-wrapper {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px;
  }

  .details-case-table {
    margin-left: 0;
  }

  .goback-action {
    margin-left: 0px !important;
    margin-top: 14px !important;
  }

  .mobile-actions {
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    margin-left: 10px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 570px) {
  .goback-action {
    margin-left: 20px !important;
    margin-top: 21px !important;
  }

  .goback-action:hover {
    background-color: transparent !important;
  }

  .button-case-action {
    width: 40px !important;
    margin-top: 22px !important;
    padding-left: 9px !important;
  }

  .button-case-action2 {
    margin-top: 22px !important;
  }

  .button-case-display {
    margin-top: 22px !important;
  }

  .mobile-actions {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 10px;
  }

  .details-case-hero {
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  .details-case-content-spacer {
    height: 0px;
  }

  .details-case-header {
    height: 70px;
    display: flex;
    left: 90px;
    padding: 0 !important;
    margin-top: -17px;
    width: auto;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .details-case-td {
    padding-left: 5px;
  }

  .details-case-header-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 10%;
    margin-left: 0;
    margin-top: 0;
  }
}
