.section-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titles {
  padding: 5px 0;
  font-size: 1em;
  font-weight: 500;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: white;
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.info-container {
  display: flex;
  align-items: center;
  padding: 15px;
  height: 100%;
  width: 100%;
  background-color: rgb(231, 231, 231);
  border-radius: 5px;
  border-top-left-radius: 0px;
  overflow: hidden;
}

.overAll-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.867);
  border-radius: 5px;
  border-top-left-radius: 0px;
  overflow: hidden;
}

.case-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.867);
  border-radius: 5px;
  border-top-left-radius: 0px;
  overflow: hidden;
}

.case-wrapper {
  height: 81%;
}

.overAll-details {
  height: 100%;
  width: 100%;
}

.overAll-details-row {
  display: flex;
  width: 100%;
  max-height: 33.3%;
  height: 25%;
  gap: 10px;
}

.overAll-details-row:nth-child(even) {
  background-color: #5455560d !important;
}

.overAll-details-row2 {
  display: flex;
  width: 100%;
  max-height: 33.3%;
  height: 20%;
  gap: 10px;
}

.overAll-details-row2:nth-child(even) {
  background-color: #22384d24 !important;
}

.overAll-details-row3 {
  background-color: #22384D;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-height: 33.3%;
  height: 20%;
  gap: 10px;
}

.case-sub-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.case-sub-title {
  font-size: 12px;
  font-weight: 300;
  color: white;
  margin: 0;
}

.case-sub-value {
  font-size: 20px;
  color: white;
}

.overAll-details-title {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
}

.overAll-details-title2 {
  min-width: 230px;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  padding-left: 30px;
}

.overAll-details-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: calc(100% - 100px);
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 5px;
}

.overAll-details-types::-webkit-scrollbar {
  display: none;
}

.overAll-details-scroller {
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 100%;
  gap: 10px;
}

.overAll-details-chip {
  border-radius: 5px;
  font-size: 10px;
  padding: 7px;
  height: 25px;
  color: white;
  margin: 0;
  cursor: pointer;
}

.no-cursor {
  cursor: default !important;
}

.overAll-details-avg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 300;
  width: calc(100% - 250px);
}

.case-description {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
}

@media screen and (max-width: 1350px) {

  .overAll-details-scroller {
    padding: 10px;
  }

  .titles {
    color: white;
    font-size: 1em;
    font-weight: 500;
  }

  .overAll-details-title2 {
    min-width: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: center;
    font-size: 16px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 900px) {
  .section-container {
    display: flex;
    flex-direction: column;
  }

  .overAll-details-title2 {
    min-width: 350px;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: center;
    font-size: 12px;
  }

  .case-description {
    font-size: 12px;
  }

  .overAll-details-avg {
    font-size: 30px;
  }

}

@media screen and (max-width: 570px) {

  .section-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .titles {
    font-size: 1em;
    font-weight: 500;
  }

  .overAll-details-title2 {
    min-width: 130px;
    max-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: center;
    font-size: 12px;
    padding-left: 30px;
  }

  .case-description {
    font-size: 10px;
    padding: 0;
  }

  .overAll-details-avg {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: calc(100% - 200px);
  }
}