.accordion-wrapper {
  gap: 1rem;
}

.accordion-wrapper-item {
  overflow: hidden;
}

.approver {
  display: flex !important;
  gap: 4px !important;
  justify-content: center !important;
  flex-direction: row !important;
  background-color: transparent !important;
  width: 15px !important;
  height: 15px !important;
  padding: 0;
  box-shadow: none !important;
  margin-right: -22px !important;
}

.accordion-add {
  min-width: 20px !important;
  max-width: 20px !important;
  height: 20px !important;
  background-color: #6f7a88 !important;
  box-shadow: none !important;
  padding-left: 20px !important;
  padding-top: 8px !important;
  border-radius: 50% !important;
  margin: 10px 10px 10px 14px !important;
}

.flex-rows {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: -110px !important;
}

.accordion-wrapper-item:nth-child(1) {
  border-radius: 20px;
}
.accordion-wrapper-item:nth-child(2) {
  border-radius: 10px;
  border-top-left-radius: 10px;
}

.accordion-toggler {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  color: black !important;
  width: 100% !important;
  margin-right: 0 !important;
  margin-top: 20px !important;
  padding: 0 10px 0 40px !important;
  cursor: pointer;
  border-radius: 5px !important;
  box-shadow: none !important;
  color: white !important;
  background-color: transparent !important;
  gap: 10px !important;
}

.accordion-toggler:hover {
  background-color: transparent !important;
}

.accordion-item {
  margin: 6px 0 0 0;
  font-size: 1em !important;
  font-weight: 800 !important;
}

.accordion-toggler-icon {
  height: 16px;
  color: #22384da9;
  font-weight: 800;
  margin-top: 7px;
}

.green {
  background-color: #52b67aa4;
  color: white !important;
}

.yellow {
  background-color: #6ad0ecc7;
  color: white !important;
}

.red {
  background-color: #cb3234;
  color: white !important;
}

.gray {
  background-color: #4a4545c3;
  color: white !important;
}

.ticket-amount-btn {
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.658);
  width: auto;
  height: 22px;
  font-size: 0.9em;
  padding: 3px 8px;
  margin: 0 3px;
}

.pdf {
  height: 27px !important;
  margin-bottom: 0px !important;
  margin-right: 2vw !important;
  border-radius: 10px !important;
}

.tire {
  min-width: 90px;
}

.accordion-wrapper-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  max-width: calc(100vw - 0px) !important;
  gap: 0px;
  overflow: hidden;
  overflow-y: scroll !important;
  padding: 10px 10px 10px 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 19px;
}

.accordion-wrapper-list::-webkit-scrollbar {
  display: none;
}

.accordion-txt {
  display: flex;
  gap: 10px;
  color: #6f7a88 !important;
}

.badge-container {
  height: 100%;
  display: flex;
  width: 250px !important;
  flex-direction: column-reverse;
  justify-content: flex-end;
  background-color: #efefef;
  color: #6f7a88;
  border-radius: 8px;
  border: 1px solid #6f7a884b;
}

.badge-container2 {
  height: 100%;
  display: flex;
  width: 250px !important;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #efefef;
  color: #6f7a88;
  border-radius: 10px;
  border: 1px solid #6f7a884b;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
  }

  40% {
    -webkit-transform: translateX(-15px);
  }

  60% {
    -webkit-transform: translateX(-7px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-15px);
  }

  60% {
    transform: translateX(-7px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.mb-nav {
  height: 15px;
  margin-right: 8px;
}

.mb-nav2 {
  height: 10px;
  margin-right: 8px;
}

.team-name {
  margin: 0;
}

.badge-izq {
  color: white;
  width: auto;
  height: auto;
  font-size: 1.2em;
  padding: 5px 15px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  gap: 5px;
}

.badge-izq2 {
  color: white;
  width: auto;
  height: auto;
  font-size: 0.7em;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bagde-wrapper {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-right: 10px !important;
  margin-bottom: 20px !important;
  height: auto !important;
  padding: 4px !important;
  border-radius: 10px !important;
}

.bagde-wrapper:hover {
  border-radius: 10px !important;
}

.bagde-wrapper2 {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-right: 10px !important;
  margin-bottom: 20px !important;
  height: auto !important;
  padding: 3px !important;
}

.bagde-wrapper2:last-child {
  margin-bottom: 40px;
}

.bagde-wrapper:last-child {
  margin-bottom: 40px;
}

.bagde-wrapper-group {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
  margin-right: 0px !important;
}

.bagde-wrapper-group:hover {
  background-color: transparent !important;
}

.bagde-container {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  background-color: transparent;
  border-radius: 8px !important;
}

.bagde-container:hover {
  background-color: rgb(108, 106, 106) !important;
}

.avatar-container {
  display: flex;
  align-items: center;
  padding: 3px;
  width: 100%;
  background-color: transparent;
  border-radius: 5px !important;
  border: 1px solid rgb(108, 106, 106) !important;
}

.avatar-container:hover {
  background-color: rgb(108, 106, 106) !important;
  color: white !important;
}

.operator-avatar {
  margin-right: 10px;
}

.badge-der {
  background-color: transparent !important;
  height: auto;
  text-align: left;
  padding: 10px;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.badge-der2 {
  background-color: rgba(79, 78, 78, 0.136) !important;
  height: auto;
  text-align: left;
  padding: 7px 10px 12px 10px;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.ticket-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: auto;
  margin-left: 10px;
}

.assigned-arrow {
  background-color: transparent !important;
  min-height: 20px !important;
  max-height: 20px !important;
  min-width: 20px;
  max-width: 20px;
  box-shadow: none !important;
  padding: 0 !important;
}

.new {
  color: white;
  background-color: rgb(239, 75, 75);
  padding: 3px 7px;
  border-radius: 8px;
  font-size: 0.8em;
}

.unassigned-arrow {
  background-color: #22384d !important;
  height: 20px !important;
  color: white !important;
  box-shadow: none !important;
  max-width: 120px;
  min-width: 70px;
  padding: 12px 10px !important;
}

.unassigned-arrow:hover {
  color: white !important;
  border: #f04 1px solid !important;
}

.badge-info {
  display: flex;
  width: 100%;
  color: white;
}

.badge-der-contact-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.8em;
  margin-bottom: 16px;
  margin-right: 20px;
  gap: 7px;
}

.badge-der-contact-name {
  max-width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  border-radius: 18px !important;
  font-size: 1.3em;
}

.badge-member {
  max-width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 350;
  padding: 2px 20px;
  color: #6f7a88;
}

.badge-member:hover {
  color: white;
}

.badge-der-contact-op {
  max-width: 100% !important;
  height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badge-der-time-assigned {
  border-radius: 5px;
  padding: 5px;
  margin-right: 0px;
  color: white;
  font-size: 0.8em !important;
  font-weight: 500;
}

.badge-der-time-difference {
  letter-spacing: 2px;
}

.badge-der-time-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7em;
  height: 0px;
  gap: 20px;
  margin-right: 0px;
  margin-bottom: 5px;
}

.refresh-btn {
  background-color: transparent !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  padding: 5px !important;
  box-shadow: none !important;
  height: 28px !important;
}

.resfresh-icon {
  height: 20px;
  width: 20px;
}

.accordion-row {
  display: flex;
}

.thunder-svg {
  fill: black !important;
  color: black;
  height: 20px;
  opacity: 0.9;
}

.modeler-table2 {
  width: 100vw;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .accordion-wrapper-list {
    padding: 0px 0px 0px 0px;
    max-width: calc(100vw - 10px);
    margin-bottom: 0px;
  }

  .accordion-toggler {
    margin-left: -0px !important;
    padding: 0 0px 0px 10px !important;
    height: 60px !important;
    width: 100vw !important;
    margin-top: 0 !important;
    display: flex;
  }

  .accordion-wrapper-item {
    width: 100%;
    padding: 0px;
  }

  .badge-der-contact-name {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 570px) {
  .tire {
    min-width: 60px;
  }
  .accordion-wrapper-list {
    padding: 0px 0px 0px 0px;
    max-width: calc(100vw - 40px);
  }
  .accordion-wrapper-item {
    width: 100%;
  }

  .bagde-wrapper {
    margin-right: 0px !important;
  }

  .ticket-actions {
    gap: 3px;
  }

  .badge-container {
    display: flex;
    width: calc(100vw - 115px) !important;
    background-color: #47678650;
    color: black;
    border-radius: 10px;
  }

  .badge-container2 {
    display: flex;
    width: calc(100vw - 115px) !important;
    background-color: #efefef;
    color: black;
    border-radius: 10px;
  }
}
