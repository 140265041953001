.event-input-text {
  width: 80%;
}

.event-name-section {
  justify-content: space-between;
  align-items: center;
}

.events-section {
  background-color: #9c9c9c38;
  padding: 2%;
  margin-top: 1rem;
}

#programmedEvents section {
  justify-content: space-around;
  align-items: center;
}

.events-dropdown {
  width: 200px;
  margin-top: 0.5rem;
}

#weebhookEvents input {
  margin-left: auto;
  width: 80%;
}

.programmed-form-control {
  margin-top: 1rem;
  align-items: center;
}

.programmed-input {
  margin-left: auto !important;
  width: 80%;
}

.programmed-input-cron {
  margin-left: auto !important;
  margin-right: 1rem !important;
  width: 54.6%;
}

.webhook-events-formControl {
  display: flex;
  margin-top: 10px;
  justify-content: left;
  align-items: center;
}

.events-radioButton {
  margin-right: 0.5rem;
}

.event-btn {
  margin: 0 0 0 10px !important;
  min-width: 100px !important;
}

.event-input-label {
  margin-left: 15px;
}

.event-input-title {
  font-size: 20px;
}

.event-btn {
  width: 160px;
}

@media screen and (max-width: 570px) {
  .event-btn {
    min-width: 190px !important;
  }
}