#statsIcon {
  width: 30px;
}

#events-table {
  display: flex;
  margin-left: auto;
}

#events-table td section {
  margin-left: 0px;
}

.table-events-description {
  margin-top: 0px;
  margin-left: 0px;
}

#events-table button {
  width: 25px;
  height: 25px;
  justify-content: center;
  background-color: #F8F9FA;
  border: none;
}

.heading {
  margin-top: 0 !important;
  margin-right: 20px !important;
  font-size: 16px !important;
}

.table-events-wrapper {
  background-color: #F8F9FA;
  padding: 5px;
}

.events-align {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.events-scroller {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 570px) {

}

#events-table button img {
  width: 25px;
  height: 25px;
}

.event-name {
  font-size: 15px;
}

.events-action {
  background-color: transparent !important;
  width: 25px !important;
  height: 30px !important;
  margin: 5px !important;
  justify-content: center;
}

.events-img {
  width: 25px;
  height: 25px;
}

.action-btns {
  width: 125px !important;
  margin-right: 0 !important;
}