.page-not-found-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(34, 56, 77);
  background: linear-gradient(125deg, rgba(34, 56, 77, 1) 40%, rgba(62, 103, 142, 1) 130%);
  /* border-left: 3px solid #f04 !important; */
}

.page-not-found-container {
  max-width: 300px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.832);
  border-radius: 20px;
  overflow: hidden;
}

.page-not-found-label {
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
  font-weight: 330;
  color: #3e678e !important;
}

.page-not-found-img {
  height: 200px;
  width: 100px;
}

.page-not-found-btn {
  background-color: #3e678e !important;
  padding: 5px 20px !important;
}
