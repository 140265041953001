.dashboard-charts-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
}

.dashboard-charts-wrapper .p-card {
    margin: 0 !important;
}

@media screen and (max-width: 900px) {
    .dashboard-charts-wrapper {
        width: 90%;
        gap: 3rem;
    }
}

@media screen and (max-width: 570px) {
    .dashboard-charts-wrapper {
        width: 90%;
        gap: 1rem;
    }
}