.action-container {
  position: absolute;
  display: flex;
  align-items: center;
  top: -12px;
  left: 19px;
}

.back {
  background-color: rgba(255, 0, 0, 0) !important;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  font-size: 1em !important;
  box-shadow: none !important;
  height: auto !important;
  width: 30px !important;
  padding: 3px 3px !important;
  color: #22384d !important;
  opacity: 0.4 !important;
}

.back:hover {
  background-color: #3e678e !important;
  color: white !important;
  opacity: 1 !important;
}

@media screen and (max-width: 900px) {
  .action-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    top: 8px;
    left: 60px;
  }

  .back {
    min-height: 30px !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 570px) {
  .action-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}
