.collection-wrapper {
    height: calc(100vh - 70px);
    margin: 2rem 0;
    box-shadow: 2px 2px 7px #0129398a;
    background-color: #efefef;
}

@media screen and (max-width: 900px) {

    .collection-wrapper {
        height: calc(100vh - 55px);
        margin: 1rem 0;
        box-shadow: none;
    }
}