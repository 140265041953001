.ticket-iframe {
  height: 100%;
  width: 50px;
  background-color: rgb(255, 255, 255);
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 20px;
}

.ticket-iframe::-webkit-scrollbar {
  display: none;
}

.loader-container {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  width: 100vw;
  min-width: 350px;
}

.modeler-iframe-wrapper {
  height: 100vh;
  height: 100vh !important;
  display: flex;
  padding-top: 0rem !important;
}

.header-wrapper2 {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  height: 50px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
}
.modeler-magic-wrapper {
  padding-top: 0rem !important;
  height: 100dvh !important;
  width: 100% !important;
  display: flex;
}

.header-wrapper {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
}

.header-go-back {
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.header-wrap {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 0 0 1.5rem;
  width: 100%;
  max-width: 1220px;
}

.claim-btn {
  background-color: #3e678e !important;
  padding: 10px 0px !important;
  min-width: 150px !important;
  border-radius: 6px !important;
}

.img-main {
  width: 110px;
}

.ticket-user {
  display: flex;
  gap: 10px;
  align-items: center;
}

.submenu-btn {
  gap: 10px !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
  width: auto !important;
  height: auto !important;
  color: black !important;
}

.submenu-btn:hover {
  background-color: transparent !important;
}
.avatar {
  color: transparent;
  border-radius: 50px;
  padding: 5px;
  font-size: 0.7em;
}
@media screen and (max-width: 1230px) {
  .modeler-magic-wrapper {
    height: 100vh !important;
  }
}

@media screen and (max-width: 900px) {
  .modeler-magic-wrapper {
    align-items: flex-start;
    height: 100vh !important;
  }

  .header-wrapper {
    margin-top: -10px;
    margin-left: -12px;
    margin-right: -12px;
  }
  .header-wrap {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 1rem 0 1rem;
    width: 100%;
    max-width: 1220px;
  }
}

@media screen and (max-width: 570px) {
  .img-main {
    width: 90px;
  }
  .modeler-iframe-wrapper {
    padding: 0px;
  }
}
