.engine-user-card {
  min-width: 230px;
  width: 320px;
  max-width: 320px;
  min-height: 150px;
  max-height: 300px;
  margin: 0.5rem;
  cursor: pointer;
  border-radius: .5rem !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 2px 2px 7px #0401008a !important;
}

.p-card-content {
  border-radius: .5rem .5rem 0 0;
}

.row {
  display: flex;
  margin: 10px 0;
}

.value {
  margin-left: 5px;
  font-weight: 400;
}

.card-header-engine-users {
  display: flex;
  justify-content: space-between;
  padding: .7rem 1rem !important;
  border-radius: .5rem .5rem 0 0;
}

.card-header-engine-users h2 {
  margin: 0.5rem 0;
  color: #fff;
}

.engine-user-card .p-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.engine-user-card h3 {
  margin: 0;
  color: white;
}

.engine-user-content-wrapper {
  display: flex;
  width: 100%;
  height: 125px !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
}

.selected {
  box-shadow: 2px 2px 10px #22384D !important;
}

.engine-user-card__statistics-wrapper {
  display: flex;
}


.engine-user-edit-section {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: -48px 21px 0px auto;
}

.data {
  line-height: 25px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 276px;
  margin: 0 !important;
  font-weight: 330 !important;
}

.editingBtn {
  margin: 0 !important;
  width: 30px !important;
  height: 30px !important;
}

.card-container {
  height: 200px;
}

.header-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  margin-top: 5px !important;
  margin-left: 5px !important;
  font-weight: 400 !important;
}

@media screen and (max-width: 900px) {

  .engine-user-card {
    min-width: 230px;
    width: 230px;
    max-width: 320px;
    min-height: 150px;
    max-height: 300px;
    margin: 0.5rem;
    cursor: pointer;
    border-radius: .5rem !important;
    background-color: rgb(255, 255, 255) !important;
    box-shadow: 2px 2px 7px #0401008a !important;
  }

  .data {
    max-width: 230px;
  }

  .owner-info-wrapper span {
    font-size: 0.8rem;
  }

  .user-opts {
    justify-content: space-between;

  }

  .customSeparator {
    display: none;
  }

  .data {
    line-height: 17px;
  }
}

@media screen and (max-width: 570px) {
  .header-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 140px;
  }

  .data {
    max-width: 190px;
  }
}