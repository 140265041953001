.apps-history-wrapper {
  width: 100%;
  height: calc(100% - 59px);
  padding: 0rem 1rem 1rem 1.7rem;
}

.instances-title {
  color: white;
  margin: 0 20px 0 0px;
}

.app-overview {
  display: flex;
  flex-direction: center;
  align-items: center;
  margin-bottom: .6rem;
  gap: 20px;
}

.action-btn {
  margin-bottom: 0 !important;
  margin-right: 10px !important;
}

.product-overview-apps {
  gap: 20px;
  width: calc(100vw - 119px);
  margin-top: 7px;
  margin-left: 0rem;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.product-overview-apps::-webkit-scrollbar {
  display: none;
}

.overview-apps-wrapper {
  display: flex;
  width: 100%;
  height: 50px;
}

.status-1 {
  background-color: transparent;
  color: rgb(95, 94, 94) !important;
}

.search-btn {
  margin: 0 0 0 10px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

@media screen and (max-width: 900px) {

  .product-overview-apps {
    margin-left: 0px;
  }

  .apps-history-wrapper {
    padding: 0 1rem 1rem 1rem;
  }
}