.activities-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.filter-box {
  height: 60px;
  display: flex;
  padding: 1.3rem 1rem 1.3rem 0;
  gap: 10px;
  align-items: center;
  height: 65px;
  width: 60vw;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.filter-box::-webkit-scrollbar {
  display: none;
}

.table-box {
  height: calc(100% - 65px);
  overflow: hidden;
  overflow-x: scroll;
}

.table-box {
  height: calc(100% - 65px);
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.table-box::-webkit-scrollbar {
  display: none;
}

.action-btn {
  margin-bottom: 0 !important;
}

.activity-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
  width: 320px;
}

@media screen and (max-width: 900px) {
  .activity-header {
    width: 220px;
    margin-left: 18px;
  }

  .table-box {
    margin-top: 0rem;
    height: calc(100% - 65px);
    overflow: hidden;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 570px) {
  .filter-box {
    align-items: left;
  }
}
