.app-form-icon-section {
  display: flex;
  font-weight: 600;
  width: 100%;
  font-size: 22px;
  align-items: center;
  justify-content: center;
}

.app-form-section {
  padding: 2%;
}

.app-form-section-2-columns {
  padding: 2%;
  columns: 2;
}

.app-formGroup {
  font-size: 16px;
  width: 100%;
  justify-content: left;
  align-items: left;
}

.app-form-inputText {
  width: 85%;
  margin-left: auto !important;
}

.app-form-button {
  margin-left: auto !important;
}

.app-input-required-text {
  margin: 0 0 0 15% !important;
}

label {
  font-weight: 500;
  padding-right: 2%;
}

.custom-data {
  white-space: pre !important;
}

@media screen and (max-width: 570px) {
  app-form-section-2-columns {
    columns: 1;
  }

  .app-formGroup {
    font-size: 12px;
  }
}