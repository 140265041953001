.app-card-wrapper {
    width: 260px;
    border-radius: 20px;
    background-color: rgb(245, 241, 241);
    box-shadow: 2px 2px 7px #0129398a !important;
}

.app-card-header {
    display: flex;
    width: 100%;
}

.app-card-header-left {
    width: 50px;
    height: 50px;
    background-color: #efef;
    border-top-left-radius: 19px;
    border-bottom-right-radius: 20px;
    margin: 0 0 3rem 0;
    filter: blur(1px);
}

.app-card-header-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 0px 7px 0px 10px;
}

.app-card-header-right-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-right-up-name {
    max-width: 160px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 330;
}

.app-card-header-right-down {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.header-right-down-actions {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0rem .5rem .5rem 1rem;
}

.app-card-body-up {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
}

.app-card-body-down {
    display: flex;
    justify-content: left;
    width: 100%;
    padding: 0;
}

.app-card-body-down-description {
    max-width: 240px;
    max-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: .5rem 0 0 0;
    font-size: 12px;
    font-weight: 300;
}

.app-card-body-left {
    width: 75%;
}

.app-card-body-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    align-items: center;
}

.app-card-footer-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: .2rem !important;

}

.button-useropt-image {
    height: 32px;
    width: 32px;
}

.user-opt-button {
    display: flex;
    justify-content: center;
    margin: 0 .2rem !important;
    padding: 0 !important;
    width: 2rem !important;
    height: 2rem !important;
    background-color: transparent !important;

}

.app-card-footer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: .1rem;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #75878a;
}

.app-card-item {
    margin: 0;
    font-weight: 345;
}

.app-card-subtitle {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 1px;
}

.delete-icon-button {
    border: none !important;
    background: transparent;
    padding: 0;
    cursor: pointer;
}

.delete-icon-button img {
    width: 28px !important;
    height: 28px !important;
}

.delete-icon-button:focus {
    border: none !important;
}



@media screen and (max-width: 900px) {
    .app-card-wrapper {
        min-width: 26px;
    }
}