.main-dashboard-wrapper {
    height: 100%;
    width: 100%;
    padding: .6rem 0rem 0rem 0rem;
}

.main-dashboard-container {
    height: 100%;
    overflow: visible;
}

.wrapper-filters {
    display: flex;
    justify-content: flex-end;
    width: 100% !important;
    overflow: hidden !important;
    margin-right: 0px !important;
    height: 40px !important;
}

.range-picker {
    min-width: 180px !important;
    height: 35px !important;
    border: none !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
}

.info-space {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.info-space-A {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 95px;
    align-items: center;
    padding-right: 2rem;
}

.info-space-B {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 35px;
    gap: 20px;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content: flex-start;
}

.info-space-B::-webkit-scrollbar {
    display: none;
}

.tooltip-box {
    padding: 15px;
    background: #667482;
    color: white;
    border-style: solid;
    background-color: rgb(107, 107, 108);
    border-width: 3px;
}

.tooltip-label {
    font-size: 12px;
    font-weight: 500;
}

.tooltip-value {
    font-size: 16px;
    font-weight: 600;
}

.box-up {
    display: flex;
    width: 100%;
    gap: 40px;
    margin-top: 10px;
}

.box-down {
    display: flex;
    width: 100%;
    gap: 20px;
    overflow: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: rgb(106, 108, 111);
    padding: 10px 20px 20px 20px;
    border-radius: 10px;
    margin-top: 10px;
}

.box-down::-webkit-scrollbar {
    display: none;
}

.box1 {
    flex-grow: 4;
    flex-basis: 500px;
}

.box2 {
    flex-grow: 8;
    flex-basis: 650px;
}

.box3 {
    flex-grow: 3;
    flex-basis: 200px;
}

.box4 {
    flex-grow: 3;
    flex-basis: 200px;
}

.box5 {
    flex-grow: 3;
    flex-basis: 200px;
}

.box6 {
    flex-grow: 3;
    flex-basis: 200px;
}

.box7 {
    flex-grow: 1;
    margin-top: 00px;
}

.global-left {
    display: flex;
    flex-direction: column;
    animation: fadeIn 5s;
}

.lines-right-top {
    display: flex;
    width: 100%;
    height: 90px;
}

.lines-right-container-top-A {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.case-name {
    color: white;
    margin-left: 10px !important;
    margin-right: 0px !important;
    margin-top: 27px;
    height: 30px;
}

.lines-right-container-top {
    padding: 15px;
    box-shadow: 2px 2px 7px #0129398a;
    background-color: rgba(255, 255, 255, 0.867);
    height: 122px;
    width: 100%;
    border-radius: 5px;
    border-top-left-radius: 0px;
}

.lines-right-container-bottom {
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 7px #0129398a;
    background-color: rgba(255, 255, 255, 0.867);
    padding: 15px;
    margin-top: 16px;
    height: 220.5px;
    width: 100%;
    border-radius: 5px;
    border-top-left-radius: 0px;
}

.totalCases-text {
    margin: 0;
    text-align: center;
}

.totalCases-value {
    margin: 0;
    margin-top: 10px;
    text-align: center;
    font-weight: 500;
}

.global-text {
    margin: 0;
    text-align: center;
    font-size: 15px;
}

.avgResolution-value {
    margin: 0;
    margin-top: 10px;
    text-align: center;
    font-weight: 500;
}

#global {
    display: flex;
    justify-content: flex-end;
    height: 50px;
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#global::-webkit-scrollbar {
    display: none;
}

#messages {
    display: flex;
    width: 100%;
    height: auto !important;
    justify-content: flex-end;
    align-items: center;
    transition: width 2s;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#messages::-webkit-scrollbar {
    display: none;
}


.logsTable {
    margin-top: 18px;
    padding: 15px;
    height: 34vh;
    box-shadow: 2px 2px 7px #0129398a;
    background-color: rgba(255, 255, 255, 0.867);
    border-radius: 5px;
}

.apexcharts-treemap-rect {
    width: 100%;
    height: 100%;
}

.filter-toggler {
    background-color: transparent !important;
    box-shadow: none !important;
}

.direction {
    margin-left: -9px !important;
}

@media screen and (max-width: 1350px) {
    #global {
        display: flex;
        justify-content: flex-start;
        height: 50px;
        margin: 0;
        overflow-x: scroll !important;
    }

    .main-dashboard-wrapper {
        padding: 0rem;
    }

    .main-dashboard-container {
        padding: 0;
        height: 100%;
    }

    .lines-right {
        gap: 20px;
        height: 100%;
        width: 100%;
    }

    .lines-right-container {
        display: flex;
        flex-direction: row;
        width: 70%;
    }

    .lines-right-container-top {
        background-color: rgba(255, 255, 255, 0.867);
        padding: 15px;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .lines-right-container-top-A {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 45%;
        padding: 15px;
        height: 111%;
    }

    .lines-right-container-top-B {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 45%;
        padding: 15px;
        height: 111%;
    }

    .lines-right-container-bottom {
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: rgba(255, 255, 255, 0.867);
        padding: 15px;
        margin: 0;
        height: 171px;
        width: 100%;
        border-radius: 10px;
    }

    .lines-right {
        display: flex;
        flex-direction: column;
        margin: 10px 0rem 0px 40px;
        height: 46%;
        width: calc(100% - 70px);
    }

    .range-picker {
        transition: width 2s;
    }

    .info-space {
        justify-content: space-between
    }

    .info-space-A {
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 65px;
        border-radius: 0px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding-right: 0px;
    }

    .info-space-A::-webkit-scrollbar {
        display: none;
    }

    .info-space-B {
        display: flex;
        margin-left: 30px;
        padding-top: 3px;
        gap: 20px;
    }

    .box {
        height: auto;
    }

    .box1 {
        flex-grow: 4;
        flex-basis: 500px;
    }

    .box2 {
        flex-grow: 8;
        flex-basis: 700px;
    }

    .box3 {
        flex-grow: 1;
        flex-basis: 250px;
    }

    .box4 {
        flex-grow: 1;
        flex-basis: 250px;
    }

    .box6 {
        flex-grow: 1;
        flex-basis: 250px;
    }

    .box5 {
        flex-grow: 1;
        flex-basis: 250px;
    }

    .box6 {
        flex-grow: 1;
        flex-basis: 250px;
    }

    #messages {
        justify-content: flex-end;
    }

    .wrapper-filters {
        margin-right: 10px !important;
    }
}

@media screen and (max-width: 900px) {
    .wrapper-filters {
        margin-right: 0px !important;
    }

    .info-space-B {
        display: flex;
    }

    .box-up {
        flex-direction: column;
        height: 100%;
        gap: 5px;
        margin-top: 0px;
    }

    .box-down {
        display: flex;
        flex-direction: column;
        height: 200%;
        min-height: 200%;
        max-height: 200%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px 14px 20px 17px;
    }

    .box {
        min-height: 255px;
    }

    .box1 {
        flex-grow: 1;
        flex-basis: 370px;
    }

    .box2 {
        flex-grow: 1;
        flex-basis: 370px;
    }

    .box3 {
        flex-grow: 1;
        flex-basis: 250px;
        height: 49%;
    }

    .box4 {
        height: 49%;
        flex-grow: 1;
        flex-basis: 250px;
    }

    .box6 {
        height: 49%;
        flex-grow: 1;
        flex-basis: 250px;
    }

    .box5 {
        height: 49%;
        flex-grow: 1;
        flex-basis: 250px;
    }

    .box6 {
        height: 49%;
        flex-grow: 1;
        flex-basis: 250px;
    }

    #messages {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 570px) {

    .lines-right-container {
        height: 100%;
        width: 100%;
    }

    .info-space-B {
        display: flex;
        margin-left: 24px;
    }

    .range-picker {
        width: 170px;
        height: 35px !important;
        border: none !important;
        margin-right: 10px !important;
        margin-left: 0px !important;
    }

    .info-container {
        padding: 15px;
    }

    .lines-right {
        flex-direction: column;
        margin: 0 0 0 10px;
        height: 100%;
        width: calc(100% - 30px);
        gap: 20px;
    }

    .lines-right-top {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        gap: 10px;
    }

    .lines-right-container-top {
        width: 100%;
    }

    .lines-right-container-bottom {
        margin: 0;
        width: 100%;
    }

    .lines-right-container-top-A {
        width: 100%;
        padding: 0;
        height: 105%;
    }

    .lines-right-container-top-B {
        width: 100%;
        padding: 0;
        height: 105%;
    }

    .case-name {
        margin-left: 0px;
    }

    #global {
        margin: 0 10px 0 0;
    }

    .box {
        min-height: 300px;
    }

    .box1 {
        flex-grow: 1;
        flex-basis: 320px;
    }

    .box2 {
        flex-grow: 1;
        flex-basis: 300px;
    }

    .box3 {
        flex-grow: 1;
        flex-basis: 500px;
    }

    .box4 {
        flex-grow: 1;
        flex-basis: 500px;
    }

    .box6 {
        flex-grow: 1;
        flex-basis: 500px;
    }

    .box5 {
        flex-grow: 1;
        flex-basis: 500px;
    }

    .box6 {
        flex-grow: 1;
        flex-basis: 500px;
    }
}