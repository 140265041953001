.modeler-iframe-wrapper {
  height: 100%;
  width: 100%;
}

.modeler-iframe-container {
  height: 100%;
  max-height: 1180px;
  overflow: hidden;
  border-radius: 0px;
  width: 100%;
  margin: 0 auto;
}

.modeler-iframe {
  height: 100%;
  width: 100%;
}

.modeler-iframe-iframe {
  height: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modeler-iframe-iframe::-webkit-scrollbar {
  display: none;
}

.js-properties-panel {
  border-left: none !important;
}

@media screen and (max-width: 900px) {
  .modeler-iframe-wrapper {
    padding: 0rem;
  }

  .modeler-iframe-header {
    margin-left: 1rem;
  }

  .modeler-iframe-container {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
  }

  .modeler-iframe {
    height: 100%;
  }

  .modeler-iframe-iframe {
    height: 100%;
  }
}
