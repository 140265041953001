/* TODO fix primereact library theming in order to remove !important as much as posible */

.p-component {
  font-weight: 300 !important;
  font-family: basiercircle-regular, sans-serif !important;
}

.p-menubar-button {
  display: none !important;
}

.stepper {
  display: flex !important;
  align-items: flex-start !important;
}

.last {
  top: 0vh !important;
}

.progress-bar {
  margin-left: 18px !important;
}

.progress {
  color: rgba(255, 255, 0, 0.745) !important;
}

.description {
  width: 29vw !important;
  font-weight: 400 !important;
}

.p-datatable-scrollable-view {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.p-datatable .p-paginator-bottom {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.p-datatable table {
  font-size: 0.8em !important;
}

.p-toast p-component {
  z-index: 100000 !important;
}

.text-label {
  margin-top: 20px !important;
}

.p-toast-message-icon {
  margin: 10px 10px 10px 20px;
}

.p-dialog-title {
  font-weight: 400 !important;
}

.p-dialog {
  min-width: 0px !important;
}

.p-dialog .p-dialog-header {
  height: auto !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  /* background-color: #22384d !important; */
  display: flex !important;
  width: 100% !important;
  color: #6f7a88 !important;
  font-weight: 600 !important;
  padding: 0px 5px 10px 30px !important;
  margin-bottom: -50px !important;
  z-index: 100000 !important;
  background-color: transparent !important;
}

input:hover {
  background-color: rgba(255, 255, 255, 0.92) !important;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.731) !important;
  z-index: 1200 !important;
  backdrop-filter: blur(10px);
}

.p-dropdown-panel {
  z-index: 15000010 !important;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

.p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: transparent !important;
}

.p-dropdown {
  background-color: #3e678e !important;
  border-color: rgba(80, 80, 80, 0.138) !important;
  box-shadow: 2px 2px 3px #0129398a !important;
  font-size: 0.8em !important;
  max-height: 30px !important;
  border-radius: 0px !important;
  margin-top: 0px !important;
  border: none !important;
}

.p-dropdown .p-dropdown-label {
  border-radius: 8px !important;
  color: rgba(0, 0, 0, 0.695) !important;
}

.p-multiselect-panel {
  z-index: 15000010 !important;
  background-color: #f4f4f4 !important ;
}

.p-dialog .p-dialog-content {
  background-color: #f2f2f2 !important;
  border-radius: 8px !important;
  padding: 0px !important;
  overflow: hidden !important;
}

.p-dropdown-trigger {
  border-radius: 50px !important;
  min-width: 30px !important;
  height: 25px !important;
  margin-top: 3px !important;
  margin-left: 0px !important;
}

/* .p-dropdown-trigger:hover {
  background-color: #4653602d !important;
} */

.p-dropdown .p-dropdown-label.p-placeholder {
  color: rgba(0, 0, 0, 0.769) !important;
  padding: 9.5px 15px !important;
}

.p-focus .p-inputwrapper-focus {
  box-shadow: none !important;
}

.p-multiselect {
  border: none !important;
  border-radius: 8px !important;
  max-width: 500px !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.p-disabled {
  background-color: rgba(159, 199, 194, 0.389) !important;
  color: #3e678e !important;
}

.p-scrollpanel-content {
  height: auto !important;
  padding-bottom: 100px !important;
}

.p-calendar .p-inputtext {
  height: 32px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  justify-content: flex-start !important;
}

.p-multiselect-items-wrapper {
  max-height: 40vh !important;
  background-color: transparent !important;
  border-radius: 0% !important;
}

.p-tabview .p-tabview-nav {
  border: none !important;
  margin: 3rem auto 1rem auto !important;
  max-width: 1170px !important;
  padding-left: 24px !important;
  z-index: 10000000;
}

.p-tabview-title {
  margin: 5px 0 !important;
  font-size: 0.9em !important;
}

.p-toast-icon-close {
  min-width: 30px !important;
}

.p-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #22384dce !important;
  border: none !important;
  height: 30px !important;
  border-radius: 3px !important;
  margin-right: 10px !important;
  box-shadow: none !important;
  font-weight: 335 !important;
  font-size: 0.9em !important;
  padding: 5px !important;
  color: white !important;
  width: auto !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  margin-top: -0.35rem !important;
  left: 0.2rem !important;
  height: 12px !important;
  max-width: 12px !important;
}

.p-inputswitch {
  width: 2.1rem !important;
  height: 18px !important;
}
.p-multiselect {
  height: 35px !important;
  max-width: auto !important;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  width: auto;
}

.p-multiselect .p-multiselect-label {
  padding-right: 0 !important;
}

.p-multiselect-clear-icon {
  display: none !important;
}

.p-button:hover {
  background-color: #f04 !important;
}

.pi {
  font-size: 1rem !important;
  background-color: transparent !important;
}

.pi-lock {
  margin-top: -13px !important;
}

.pi-user {
  margin-top: -13px !important;
}

.p-dropdown-label p-inputtext p-placeholder {
  font-size: 0.8em !important;
}

.p-inputtext {
  width: 100% !important;
  border-color: transparent !important;
}

.p-inputtext:enabled {
  border-color: transparent !important;
  font-size: 1em !important;
}

.p-input-icon-left > .p-inputtext {
  background-color: rgba(255, 255, 255, 0.855) !important;
  border-color: #22384d !important;
}

p-inputtext:hover {
  border-color: #22384d !important;
}

.p-inputtext:enabled:hover {
  border-color: #22384d !important;
}

.p-inputtext:enabled:focus {
  border-color: #22384d !important;
}

td {
  padding: 5px 3px 3px 3px !important;
}

.p-tabview-nav-link:hover {
  border-color: none !important;
}

.p-multiselect:hover {
  border-radius: 5px !important;
}

.p-button.p-button-info {
  background-color: #22384d !important;
}

.p-checkbox .p-checkbox-box {
  border-radius: 50% !important;
}
.pi-check:before {
  height: 5px !important;
}

.p-unselectable-text .p-tabview-selected .p-highlight {
  box-shadow: none !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  box-shadow: none !important;
  color: white !important;
  border-color: transparent !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent !important;
  color: #6f7a88 !important;
  font-weight: 350 !important;
  border-color: transparent !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  background-color: #f04 !important;
  color: white !important;
}

.p-multiselect-label {
  color: white !important;
}

.p-multiselect .p-multiselect-label .p-placeholder {
  padding-right: 0px !important;
}

/* .pro-sidebar {
  border-left: 2px solid #f04 !important;
} */

.carousel .control-dots .dot {
  background-color: rgb(36, 53, 76) !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #22384dc4 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #22384dc4 !important;
}

.pro-sidebar-footer {
  margin-bottom: 15px !important;
}

.p-paginator {
  background-color: #3a4652ce !important;
  justify-content: right !important;
  padding: 0.2rem 0.7rem !important;
  justify-content: center !important;
}

.p-paginator-bottom {
  border: 0px !important;
}

.pi-chevron-down {
  color: whitesmoke !important;
}

.p-paginator-element {
  color: white !important;
  min-width: 25px !important;
  height: 25px !important;
}

.p-paginator-element:hover {
  color: white !important;
  background-color: #f04 !important;
}

.p-highlight {
  color: white !important;
  background-color: #3e678e !important;
  border-radius: 0 !important;
}

.p-highlight:hover {
  color: white !important;
  background-color: rgba(34, 56, 77, 0.575) important;
}

.p-datepicker {
  background-color: #22384d !important;
  z-index: 30000000 !important;
  color: white !important;
  border-color: #f04 !important;
  border-width: 3px !important;
  border-style: solid !important;
}

.p-datepicker-header {
  background-color: #22384d !important;
  border: none !important;
  color: white !important;
}

.p-datepicker-next {
  color: white !important;
  background-color: transparent !important;
}

.p-datepicker-next:hover {
  background-color: #f04 !important;
}

.p-datepicker-prev:hover {
  background-color: #f04 !important;
}

.p-datepicker-prev {
  color: white !important;
  background-color: transparent !important;
}

.p-datepicker-today span {
  background-color: #22384d !important;
  color: white !important;
}

.p-datepicker span:hover {
  background-color: #f04 !important;
  z-index: 30000000 !important;
  color: white !important;
}

.p-treetable-emptymessage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background-color: white !important;
}

.p-datatable-emptymessage {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 80vw;
  height: 50px;
  background-color: #f2f2f2 !important;
}

.p-datatable-scrollable-header {
  background-color: #f2f2f2 !important;
}
.p-datatable-header {
  color: #f2f2f2 !important;
  text-align: center;
  font-size: 1em !important;
}

.p-card .p-card-content {
  padding: 0 !important;
  height: auto !important;
}

.p-toast-summary {
  font-size: 0.9em !important;
}

.p-toast-detail {
  font-size: 1em !important;
}

.p-toast-message-text {
  margin-left: 10px !important;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 5px 0 !important;
}

.p-card-body {
  padding: 0px 10px 10px 10px !important;
}

.p-card-content {
  overflow: hidden !important;
}

.p-dialog-header-close {
  margin-right: 0px !important;
  margin-top: 0px !important;
  outline-style: none !important;
}

.p-dialog-header-close:hover {
  background-color: #f04 !important;
  color: white !important;
  outline-style: none !important;
}

.p-paginator-bottom {
  padding-left: 5px !important;
}

/* Show downloads in charts */
.apexcharts-toolbar {
  display: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.6rem !important;
  font-weight: 400 !important;
}

.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding: 0.5rem !important;
}

.p-dropdown-items-wrapper {
  z-index: 1000000 !important;
  max-height: 400px !important;
}

.p-dropdown-item {
  padding: 0 !important;
  font-size: 1em !important;
}

.p-inputswitch-slider {
  box-shadow: 2px 2px 7px #0129398a !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #5ace39 !important;
}

.p-inputswitch {
  min-width: 40px;
}

.p-inputswitch .p-inputswitch-slider {
  background: rgba(155, 149, 149, 0.674) !important;
}

.p-dropdown-trigger {
  width: 15px !important;
  margin-right: 0px !important;
}

.p-dropdown-label {
  color: white !important;
  display: none !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
  height: 40px;
  width: 40px;
  margin-top: 54px !important;
}

.control-arrow:hover {
  background-color: transparent !important;
}

.control-dots {
  margin-bottom: 0px !important;
}

.carousel .control-dots {
  z-index: 100000 !important;
}

hr {
  border-color: rgba(245, 245, 245, 0.496);
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.pro-item-content {
  font-size: 0.9em !important;
}

@media screen and (max-width: 1400px) {
  .p-tabview {
    margin: 2rem 20px 1rem 30px !important;
  }
  .p-tabview .p-tabview-nav {
    border: none !important;
    margin: 3rem 0px 0rem 0px !important;
    max-width: 1150px !important;
  }
}

@media screen and (max-width: 900px) {
  .p-tabview .p-tabview-nav {
    border: none !important;
    margin: 2rem 0px 1rem 0px !important;
    max-width: 1150px !important;
    padding-left: 10px !important;
  }
  .p-datatable-emptymessage {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 85vw;
    height: 50px;
    background-color: #f2f2f2 !important;
  }

  .p-dialog .p-dialog-header {
    background-color: transparent !important;
    z-index: 1 !important;
  }
  .description {
    width: 63vw !important;
  }

  input:hover {
    background-color: #f2f2f2 !important;
  }
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
    height: 40px;
    width: 40px;
    margin-top: 35px !important;
  }

  .p-paginator-element {
    color: white !important;
    min-width: 20px !important;
    height: 25px !important;
  }

  .p-button:hover {
    background-color: none !important;
  }

  .p-treetable-emptymessage {
    height: 19vh;
  }

  .pro-item-content {
    font-size: 0.6em !important;
  }
}

@media screen and (max-width: 570px) {
  .p-tabview {
    margin: 2rem 20px 1rem 20px !important;
  }

  .p-toast-message {
    width: 100vw !important;
    margin-left: -35vw !important;
    margin-top: -20px !important;
  }

  .p-dialog .p-dialog-header {
    background-color: transparent !important;
    z-index: 1 !important;
    margin-bottom: -40px !important;
  }
}

@font-face {
  font-family: basiercircle;
  src: url('./assets/basiercircle.otf');
}

@font-face {
  font-family: basiercircle-regular;
  src: url('https://mibanco.com.sv/webfont/basiercircle-regular.woff') format('woff');
}

@font-face {
  font-family: basiercircle-bold;
  src: url('https://mibanco.com.sv/webfont/basiercircle-bold.woff') format('woff');
}

@media screen and (max-width: 570px) {
}
