.account-billing-wrapper {
    height: 100%;
    width: 100%;
    padding: 2rem 0 0 0;
}

.account-billing-container {
    padding: 2rem;
    background-color: #efefef;
    overflow-y: scroll;
    height: 93vh;
    box-shadow: 2px 2px 7px #0129398a;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.account-billing-container::-webkit-scrollbar {
    display: none;
}

.account-billing-general {
    display: flex;
    flex-direction: row;
}

.account-billing-counters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: right;
    width: 100%;
    gap: 40px;
}

.account-billing-actions {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    gap: 1rem;
    margin-bottom: -8px;
    margin-left: 20px;
}

.account-billing-charts {
    margin: 3rem 0;
    display: flex;
    justify-content: center;
    gap: 40px;
}

.account-billing-accounts {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
    margin-top: 0;
}

.account-billing-subscribers {
    width: 70%;
}

.account-billing-staff {
    width: 30%;
}

.account-billing-footer {
    margin-bottom: 2rem;
}

.actions {
    height: 40px !important;
}

.in-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
    height: 60vh;
    background-color: rgba(190, 190, 190, 0.392);
    color: white;
}

@media screen and (max-width: 900px) {
    .actions {
        margin-left: 0% !important
    }

    .account-billing-wrapper {
        padding: 1rem;
    }

    .account-billing-container {
        width: 100%;
        height: 88vh;
    }

    .in-progress {
        height: 33vh;
    }

}


@media screen and (max-width: 570px) {
    .in-progress {
        height: 160px;
    }

    .actions {
        margin-left: 9% !important
    }

    .account-billing-wrapper {
        padding: 1rem 0;
    }

    .account-billing-container {
        padding: 0rem;
        width: 100%;
        height: 87vh;
    }

    .account-billing-general {
        display: flex;
        flex-direction: column-reverse;
    }

    .account-billing-counters {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .account-billing-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 2rem;
        margin-right: 0;
        margin-top: 20px;
    }

    .account-billing-charts {
        margin: 1.5rem 0;
        width: 70vw;
    }

    .account-billing-subscribers {
        width: 100%;
    }

    .account-billing-staff {
        width: 100%;
    }

    .account-billing-footer {
        margin-top: 0rem;
        width: 100%;
    }

    .account-billing-accounts {
        display: none;
        /* display: flex; */
        flex-direction: column;
    }
}