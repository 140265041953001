.pin {
  position: fixed;
  z-index: 1010;
}

.list-wrapper {
  display: absolute;
  width: auto;
  background-color: rgba(97, 93, 93, 0.8);
  transition: width 1s;
  border-radius: 10px;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 27px;
}

.list-wrapper::-webkit-scrollbar {
  display: none;
}

.list-expand {
  pointer-events: auto;
  width: 30px;
}

.list-ul {
  color: white;
  margin: 0;
  margin-left: -13px;
}

.list-ul:hover {
  background-color: #f04 !important;
}

.activeTab {
  background-color: #f04;
}

.list-li {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px;
}

.list-ul .list-li::before {
  content: "\2022";
  color: white;
  font-weight: 500;
  display: inline-block;
  height: 2.5em;
  margin-left: -28px;
  margin-top: 40px;
  padding: 0;
  font-size: 30px;
}

.list-ul {
  z-index: 4000000;
}


.list-item {
  display: block;
}

.list-pin {
  display: flex;
  margin: 0 !important;
  justify-content: center;
  align-content: center;
  width: 100% !important;
  background-color: transparent !important;
  padding: 10px !important;
  margin-left: 11px !important;
  box-shadow: none !important;
  padding-right: 40px;
}

.list-pin:hover {
  color: white !important;
}

.list-pin-active {
  color: white !important;
}

.pin-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 120px;
  max-width: 120px;
  text-align: left;
}

.pinEmpty {
  height: 0px;
}

.chipFilter {
  width: 100% !important;
  color: white;
  background-color: transparent;
}

.filters {
  min-width: 570px;
}

.filters-activity {
  min-width: 560px;
}

.lined {
  color: transparent;
  margin-left: 5px;
  margin-right: 15px;
}

@media screen and (max-width: 1350) {
  .list-wrapper {
    margin-left: 0px;
  }
}

@media screen and (max-width: 570) {}