.history-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 2rem 0;
}

.history-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 93vh;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.history-A {
  width: 100%;
  display: flex;
  padding: 10px 1rem 10px 10px;
  gap: 10px;
  align-items: center;
  height: 60px;
  max-width: 87vw;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.history-A::-webkit-scrollbar {
  display: none;
}

.history-B {
  height: calc(100% - 70px);
  width: 100%;
}

.history-A-people {
  width: auto;
  margin: 2px 0px 0 15px;
  height: auto;
  display: flex;
  align-items: center;
}

.history-A-filters {
  display: flex;
  gap: 10px;
}

.history-A-dropdown {
  width: 100% !important;
  align-self: center;
  background-color: transparent !important;
  border: none !important;
  max-width: 140px !important;
}

.history-A-search {
  height: 30px;
  z-index: 1000;
}

.p-multiselect-header {
  display: none !important;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0 !important;
}

.p-multiselect-item {
  padding: 5px 25px 5px 15px !important;
  width: 100% !important;
  display: flex !important;
  font-weight: 400 !important;
  background-color: #867f7f !important;
  color: white !important;
}

.p-multiselect-item .p-highlight {
  background-color: #235faf !important;
  border-color: #235faf !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: #f04 !important;
}

.history-ppl-button {
  border-radius: 60% !important;
  font-size: 12px !important;
  height: 38px !important;
  width: 38px !important;
  margin-left: -13px !important;
  margin-bottom: 4px !important;
  margin-right: 5px !important;
  color: white !important;
  display: flex;
  justify-content: center;
}

.history-ppl-button:hover {
  z-index: 2000 !important;
  font-size: 14px !important;
  padding: 18px 16px !important;
}

.history-avatar {
  border-radius: 50% !important;
  font-size: 14px !important;
  height: 38px !important;
  width: 43px !important;
  margin-bottom: 0px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.avatar-align {
  height: 30px !important;
  width: 35px !important;
  border-radius: 50% !important;
  font-size: 12px !important;
}

.avatar-align:hover {
  background-color: #22384d !important;
  cursor: default;
}

.avatar-align-empty {
  background-color: transparent !important;
  box-shadow: none !important;
  height: 30px !important;
  width: 35px !important;
  border-radius: 50% !important;
  font-size: 12px !important;
  color: black !important;
}

.avatar-align-empty:hover {
  background-color: transparent !important;
  cursor: default;
}

.chip {
  height: 18px;
  width: auto;
  margin: 0;
  padding: 0px 10px;
  text-align: left;
  font-size: 15px;
}

.history-A-clear {
  margin-top: 10px !important;
  display: flex;
  justify-content: center;
  background-color: transparent !important;
  color: #ffffff !important;
  box-shadow: none !important;
  min-width: 35px !important;
}

.history-A-users {
  background-color: transparent !important;
  height: 30px !important;
  margin-left: -5px !important;
}

.statusChip {
  display: flex;
  justify-content: center;
}

.groupChip {
  display: flex;
  justify-content: center;
  height: 30px;
  padding: 6px 10px 5px 10px;
  border-radius: 15px;
  color: white;
  align-content: center;
  font-size: 14px;
  width: auto !important;
}

.dot {
  height: 8px;
  width: 8px;
  min-width: 8px;
  border-radius: 20px;
  display: inline-block;
}

.dot2 {
  height: 22px;
  width: auto;
  min-width: 22px;
  border-radius: 5px;
  display: inline-block;
  cursor: context-menu;
}

.dot-value {
  padding: 0 !important;
  margin: 4px !important;
  color: white;
  font-size: 11.5px;
}

.wrapper-conditions {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100px;
  border-radius: 10px;
}

.conditions {
  margin: 0px !important;
  font-size: 16px !important;
  font-weight: 350 !important;
}

.wrapper-groups {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 7px 15px;
  width: 150px;
  gap: 10px;
  border-radius: 10px;
}

.wrapper-groups2 {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  margin: auto !important;
  align-items: center;
  padding: 7px 15px;
  border-radius: 10px;
  width: 200px !important;
  display: flex !important;
  margin-left: 50px !important;
}

/* @media screen and (max-width: 1300px) {
  .wrapper-groups2 {
    width: 230px !important;
  }
} */

@media screen and (max-width: 900px) {
  .wrapper-groups2 {
    width: 110px !important;
  }
}

.groups {
  margin: 0px !important;
  color: white;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 276px;
}

.green {
  background-color: #52b67a;
}

.yellow {
  background-color: #eccd6a;
}

.red {
  background-color: #cb3234;
}

.editButton {
  display: flex;
  justify-content: center;
}

.editionButton:hover {
  background-color: #f04 !important;
}

.editionButton {
  margin: 0 !important;
  height: 30px !important;
  width: 30px !important;
}

.conditionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.list-empty-wrapper {
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .list-empty-wrapper {
    height: 87%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 30px;
  }
  .history-wrapper {
    padding: 0;
    margin-top: 1rem;
  }

  .history-container {
    height: 93.5vh;
    box-shadow: none;
  }

  .history-A {
    width: 100%;
  }

  .table-wrapper-history {
    padding: 0 0 0 2rem;
  }

  .history-dropdown {
    width: 70% !important;
  }
}
