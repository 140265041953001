.setting-details {
  height: 100%;
  width: 100%;
  background-color: rgba(246, 245, 245);
}

.setting-details-close {
  display: flex;
  justify-content: flex-end;
}

.save-btn {
  margin-top: 5px !important;
  width: 50px !important;
  padding-left: 10px !important;
}

.team-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.close2 {
  position: relative;
  height: 35px !important;
  width: 35px !important;
  cursor: pointer !important;
  margin-top: 20px !important;
  margin-right: 15px !important;
  box-shadow: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.close2:after {
  content: 'x';
  margin-right: 7px !important;
  height: 20px !important;
  width: 20px !important;
  background-color: transparent !important;
  color: black !important;
}

.setting-details-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: black;
  height: calc(100% - 60px);
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.setting-details-content::-webkit-scrollbar {
  display: none;
}

.setting-details-picker {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding-left: 30px;
}

.setting-details-dragger {
  padding: 0 30px 30px 30px;
}

.wrapper-selected {
  min-height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper-end {
  justify-content: flex-end;
}

.setting-details-color-txt {
  color: white;
}

.team-title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: white;
  margin-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 280px;
}

.setting-details-profile-wrapper {
  height: 100%;
}

.setting-details-profile {
  height: 100px;
  margin-left: 50px;
  margin-bottom: 50px;
}

.setting-details-profile-2 {
  height: 22vh;
  width: 100%;
  margin-left: 0px;
}



@media screen and (max-width: 900px) {
  .setting-details-color-txt {
    display: none;
  }

  .setting-details-profile-2 {
    display: none;
  }

  .setting-details-close {
    display: flex;
    justify-content: flex-end;
  }

  .setting-details-content {
    height: calc(100% - 50px);
  }

  .close2 {
    margin-top: 10px !important;
    margin-right: 5px !important;
  }

  .wrapper-selected {
    height: 40px;
  }

  .setting-details-profile {
    height: 70px;
    margin-left: 30px;
  }

  .setting-details-dragger {
    padding: 0 10px;
  }

  .setting-details-picker {
    padding-left: 10px;
  }

  .team-title {
    margin-right: 10px;
  }
}