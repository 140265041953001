.root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.app-content {
  flex: 1;
}

.app-container {
  display: flex;
  justify-content: center;
  border: 1px #22384d;
  border-style: solid;
  box-shadow: 2px 2px 7px #0129398a;
  width: 100vw;
  height: 100vh;
  max-height: 1180px !important;
  max-width: 2000px !important;
}

.app-wrapper {
  width: 100%;
  height: 100%;
}

.page-container {
  background-color: #f2f2f2;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.head {
  z-index: 1000;
  height: 96px;
  width: 100%;
  background-color: rgb(186, 186, 186);
}

.body {
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
