.HI-wrapper {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

.HI-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
}

.HI-expired {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f2f2f2;
}

.logout-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.expired-img {
  height: 100px;
}

.expired-header {
  height: 30px;
  margin-top: 40px;
  font-size: 1.1em;
  font-weight: 500;
  color: black;
  text-align: center;
  padding: 0 50px;
  max-width: 500px;
}

.header-cls {
  display: flex;
  align-items: center;
  width: 100vw;
  background: linear-gradient(125deg, rgba(34, 56, 77, 1) 40%, rgba(62, 103, 142, 1) 130%);
  height: 180px;
}

.logo-img {
  height: 90px;
}

.header-left {
  display: flex;
  align-items: center;
  width: 84%;
  height: 100%;
}
.header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 15%;
  height: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  height: 80px;
  width: 100%;
  max-width: 1100px;
  padding: 0 30px 0 40px;
}

.refresh-img {
  height: 20px;
  width: 20px;
}

.refresh-bt2 {
  background: transparent !important;
  box-shadow: none !important;
}

.HI-listing {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0px 0px 30px 0px;
  max-width: 1100px;
  margin: 0 auto;
  overflow-y: scroll;
  background: rgb(242, 242, 242);
}

.loggin-btn2 {
  padding: 20px 20px !important;
  display: flex !important;
  gap: 5px;
  font-size: 1.1em !important;
}

.HI-details {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.add-ticket-btn {
  min-height: 24px !important;
  max-height: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #22384d !important;
  box-shadow: 2px 2px 7px #0129398a !important;
  padding-left: 4.5px !important;
  border-radius: 50% !important;
  margin-right: 5px !important;
  margin-left: 0px !important;
}

.HI-listing-search {
  height: 2.5rem;
}

.HI-listing-container {
  height: 100%;
  margin-top: -10px;
}

.p-highlight {
  box-shadow: none !important;
}

input {
  font-size: 1.1em !important;
}

.p-input {
  width: 100% !important;
}
.p-inputtext {
  border-radius: 8px !important;
}

.p-input-icon-left > .p-inputtext {
  width: 100% !important;
  padding: 0 2rem 0 2rem !important;
  box-shadow: none !important;
}

.p-input-icon-left > i:first-of-type {
  left: 0.6rem !important;
  top: 1.3rem !important;
}

.search-wrapper {
  display: flex;
  align-items: center;
  padding: 0px;
  border-radius: 0px;
  width: 100%;
  height: 95px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-ticket-clear {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-left: 10px !important;
  margin-bottom: 0 !important;
  min-width: 2px !important;
  width: 25px !important;
  height: 25px !important;
}

.nav-icon-out {
  width: 20px;
  height: 28px;
  padding: 5px 0;
  color: black !important;
  fill: black !important;
}

.group-filter-wrapper {
  width: 100%;
  max-width: 110px;
  height: 30px;
  margin-left: 10px;
  margin-top: -1px;
}

.loggin-btn {
  margin: auto 0 !important;
  width: 30px !important;
  padding: 0px !important;
  background-color: transparent !important;
}

.loggin-btn:hover {
  color: white !important;
  fill: white !important;
}

.detail-actions {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.detail-wrapper {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.detail-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 90%;
  background-color: #ffffffe2;
  padding: 50px;
  border-radius: 30px;
  max-width: 1200px;
}

.loggin-btn2 {
  background-color: #22384d;
  min-width: 170px;
}

.detail-img {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 150px !important;
}

.refresh-basket {
  color: white !important;
  width: 100px !important;
  margin: -10px !important;
}

.detail-message {
  font-size: 1.3em;
  text-align: center;
  margin: 20px 0 10px 0 !important;
  color: #22384d !important;
}

.buttonFilter {
  height: 45px !important;
  margin-left: 30px !important;
  margin-bottom: 00px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  min-width: 270px !important;
  max-width: 20vw !important;
  color: black !important;
  border: none !important;
  padding-left: 15px !important;
}

.dot {
  height: 8px;
  width: 8px;
  min-width: 8px;
  border-radius: 20px;
  display: inline-block;
}

.dot2 {
  margin: 7px 0;
  height: 22px;
  width: auto;
  min-width: 22px;
  border-radius: 5px;
  display: inline-block;
  cursor: context-menu;
}

.dot-value {
  padding: 0 !important;
  margin: 4px !important;
  color: white;
  font-size: 11.5px;
}

.wrapper-conditions {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 0px;
  width: 100px;
  gap: 10px;
  border-radius: 10px;
  color: white;
}

.empty-listing {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: white !important;
  gap: 10px;
  flex-direction: column;
  margin-left: -35px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.empty-basket {
  height: 7vmin;
}

.detail-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .HI-details {
    overflow: hidden;
  }
  .header-left {
    display: flex;
    align-items: center;
    width: 75%;
    height: 100%;
  }
}

@media screen and (max-width: 900px) {
  .detail-actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  .header-cls {
    display: flex;
    align-items: center;
    width: 100vw;
    background-color: #22384d;
    height: 120px;
  }
  .buttonFilter {
    height: 45px !important;
    margin-left: 10px !important;
    margin-bottom: 00px !important;
    border-radius: 8px !important;
    font-size: 16px !important;
    min-width: 80vw !important;
    color: black !important;
  }
  .buttonFilter {
    height: 45px !important;
    margin-left: 20px !important;
    margin-bottom: 00px !important;
    font-size: 16px !important;
    max-width: 80vw !important;
    color: black !important;
  }
  .search-wrapper {
    display: flex;
    align-items: center;
    background-color: rgba(104, 102, 102, 0.415);
    padding: 0px;
    border-radius: 0px;
    width: 100%;
    height: 85px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .logo-img {
    height: 70px;
  }
  .header-left {
    display: flex;
    align-items: center;
    width: 75vw;
    height: 100%;
  }
  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20vw;
    height: 100%;
  }
  .nav-icon-out {
    width: 20px;
    height: 20px;
    padding: 2px 0;
  }
  .HI-listing {
    height: 100%;
    width: 100%;
    padding: 0;
  }
  .add-ticket-btn {
    width: 75px !important;
  }

  .empty-listing {
    margin-left: 0;
  }

  .detail-img {
    display: flex;
    flex-direction: column;
  }

  .HI-container {
    flex-direction: column;
    background-color: transparent;
  }

  .HI-listing-container {
    height: 100%;
    width: 100%;
    margin-top: 0px;
    padding-bottom: 300px;
  }

  .HI-listing-search {
    font-size: 14px !important;
    width: 100% !important;
  }

  .HI-details {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

.loader-wrapper {
  height: 100vh;
  width: 100%;
}

@media screen and (max-width: 570px) {
  .header-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    height: 80px;
    width: 100%;
    max-width: 1100px;
    padding: 0 20px;
  }
  .buttonFilter {
    height: 45px !important;
    margin-left: 20px !important;
    margin-bottom: 00px !important;
    font-size: 16px !important;
    min-width: 73vw !important;
    color: black !important;
  }
  .HI-details {
    width: 100%;
  }

  .detail-message {
    width: 70vw;
    margin: 20px 0 !important;
  }
}
