.user-engine-box {
  width: 100%;
  height: 300px;
  border: 3px solid #021f3cb2;
}

.user-engine-drag-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-engine-drag-list {
  list-style: none !important;
  padding-left: 20px !important;
}

.user-engine-angle-icon {
  font-size: 1.5em !important;
}

.user-engine-drag-button {
  height: 100%;
  margin: 0 !important;
}

.u-e-drag-button-section {
  padding: 0.5rem;
  height: 100%;
}

.user-engine-item {
  cursor: pointer;
  margin: 0 !important;
}

.user-engine-item:hover {
  font-size: 20px;
}

.user-engine-selectedItem {
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  color: #f04;
  margin: 0 !important;
}

.user-engine-selectedItem:hover {
  font-size: 20px;
}

.user-engine-titles {
  justify-content: space-between;
}

.dragger-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dragger-container {
  display: flex;
  gap: 10px;
  padding: 10px 10px 0;
}

.action-footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.user-subtitle-form {
  font-weight: 500;
}


@media screen and (max-width: 900px) {

  .user-engine-drag-list {
    padding-left: 13px !important;
    margin-top: 10px !important;
  }

  .user-engine-drag-section {
    flex-direction: column;
  }

  .user-engine-angle-icon {
    font-size: 1.2em !important;
  }

  .user-engine-box {
    width: 100%;
    height: calc(35vh);
    border: 2px solid #6466685e;
  }

  .user-subtitle-form {
    font-size: 14px;
  }
}

@media screen and (max-width: 570px) {

  .user-engine-item:hover {
    font-size: 16px;
  }

  .user-engine-selectedItem {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    color: #f04;
    margin: 0 !important;
  }

  .user-engine-box {
    width: 100%;
    height: 17vh;
  }

}