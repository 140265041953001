.tab-section-wrapper {
  margin: 2rem 0 0 1rem;
}

.p-tabview .p-tabview-panels {
  padding: 0px !important;
  margin: 2rem 2rem 0rem 1.5rem;
  background: transparent !important;
  border: none;
}

.p-tabview-panels {
  padding: 0px !important;
  margin-top: -30px !important;
}

.top-adjust {
  margin-top: 30px !important;
}

.action-header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 20px 0 0 0;
}

.p-tabview-nav {
  margin: 0.8rem 0 0 1.5rem !important;
  background-color: transparent !important;
}

.p-tabview-nav-link {
  padding: 0.4rem 0.3rem 0.2rem 0.3rem !important;
  width: 80px !important;
  display: flex !important;
  justify-content: center !important;
}

.p-tabview-selected {
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.section-container-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.section-container-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 70px;
  margin: 2rem;
}

.section-header {
  padding: 1rem;
  width: 34%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-info-container {
  width: 30%;
  min-width: 180px;
  max-width: 350px;
  margin: 1rem 0.7rem;
  padding: 1rem 1rem;
  background-color: #fff;
  border-style: solid;
  border-color: rgba(195, 195, 231, 0.433);
}

.account-info-items-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.account-info-button-wrapper {
  margin-right: 10%;
}

.action-button {
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px !important;
  box-shadow: 6px 6px 100px 2px rgba(97, 93, 93, 0.34);
  -webkit-box-shadow: 6px 6px 100px 2px rgba(93, 93, 93, 0.34);
  -moz-box-shadow: 6px 6px 100px 2px rgba(97, 93, 93, 0.34);
  background-color: rgba(97, 93, 93, 0.8) !important;
}

.engine-options {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.engine-list-wrapper {
  display: flex;
  height: calc(100% - 60px);
  overflow: hidden;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  overflow: hidden;
  gap: 10px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.engine-list-wrapper::-webkit-scrollbar {
  display: none;
}

.button {
  width: 190px;
  border: 1px solid #fff;
  box-shadow: 2px 2px 5px #023347;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
}

.button:hover {
  box-shadow: 2px 2px 7px #0129398a;
}

.engine-section {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  width: calc(100% - 0rem);
  height: calc(100vh - 75px);
  box-shadow: 2px 2px 7px #0129398a;
  background-color: #efefef;
}

.actioner {
  height: 32px !important;
  width: 150px !important;
  margin: 0px !important;
  margin-left: 20px !important;
}

.icon {
  font-size: 2rem;
  color: white;
}

.section-subscribers-wrapper {
  display: flex;
  align-items: center;
}

.section-subscribers-list-container {
  width: 85%;
}

.section-subscribers-buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 15%;
}

.section-subscribers-buttons-wrapper button {
  margin: 1rem 0;
  width: 100%;
}

.table-header {
  justify-content: flex-start;
}

.statics-number {
  font-size: 30px;
  line-height: 0px;
  color: #f6b41a;
  font-weight: 500;
}

.statics-tag {
  font-size: 1rem;
  line-height: 0px;
}

.loading-container {
  margin-top: 30px;
  height: 85vh;
}

.section-container-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  overflow-y: scroll;
  min-height: 30vh;
  max-height: 80vh;
}

.section-container-wrapper {
  -ms-overflow-style: none;

  scrollbar-width: none;
}

.section-container-wrapper::-webkit-scrollbar {
  display: none;
}

.modal-title {
  margin-top: -10px !important;
  margin: 0;
  text-align: left;
}

.btn-add {
  margin: 0 !important;
  min-height: 40px;
  min-width: 220px;
  align-self: right !important;
}

@media screen and (max-width: 900px) {
  .top-adjust {
    margin-top: 17px !important;
  }

  .loading-container {
    margin-top: 18px;
    height: 85.6vh;
  }

  .p-tabview-nav {
    margin: 0.5rem 0 0 1rem !important;
    width: 90% !important;
  }

  .p-tabview-nav-link {
    background-color: transparent !important;
  }

  .p-tabview-panels {
    margin: -16px 1rem 0 1rem !important;
  }

  .tab-section-wrapper {
    margin: 0 2rem;
  }

  .section-container-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
    gap: 1rem;
  }

  .counter-display-wrapper {
    max-width: 80%;
  }

  .spanner {
    display: none;
  }

  .engine-list-wrapper {
    height: calc(100% - 70px);
    justify-content: center;
  }

  .action-button {
    padding-right: 20px;
    height: 70px !important;
  }

  .account-info-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  .account-info-items-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .account-info-button-wrapper {
    margin: 1rem 0 0 0;
  }

  .statics-number {
    font-size: 25px;
  }

  .engine-section {
    margin: 1rem 0 0 0;
    padding: 0rem;
    width: 100%;
    box-shadow: none;
    height: calc(100vh - 55px);
  }
}
