.wrapper-submenu {
  position: absolute !important;
  top: 30px !important;
  right: 27px !important;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(69, 69, 69, 0.15);
  border-radius: 20px;
  padding: 20px 20px 20px 20px;
  width: 300px !important;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.wrapper-row {
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 10px;
}

.label-txt {
  color: #6f7a88;
  margin: 0 !important;
  font-size: 0.8em !important;
}

.value-txt {
  color: #050606;
  margin: 0 !important;
}

.logout-btn {
  background: #22384d !important;
  border-radius: 16px !important;
  width: 200px !important;
  height: 38px !important;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
}
.action-submenu {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .wrapper-submenu {
  }
}

@media screen and (max-width: 570px) {
  .wrapper-submenu {
    position: absolute !important;
    top: 30px !important;
    right: 30px !important;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(69, 69, 69, 0.15);
    border-radius: 20px;
  }
}
