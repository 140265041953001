.user-form-icon-section {
  align-items: center;
}

.user-form-section {
  padding: 0 !important;
}

.user-formGroup {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px !important;
  margin: 1% 0 1% 0 !important;
  gap: 10px;
}

.user-form-inputText {
  min-width: 200px;
  margin-left: auto !important;
}

.user-form-button {
  margin-left: auto !important;
  margin-bottom: 0 !important;
}

.user-input-required-text {
  margin: 0 0 0 15% !important;
}