.page-not-authorized-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-left: 3px solid #f04 !important; */
}

.page-not-authorized-container {
  max-width: 300px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.832);
  border-radius: 20px;
  overflow: hidden;
}

.page-not-authorized-label {
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
  font-weight: 330;
}

.page-not-authorized-img {
  height: 200px;
  width: 100%;
}

.page-not-authorized-btn {
  background-color: #3e678e !important;
  padding: 5px 20px !important;
}

@media screen and (max-width: 900px) {
  .page-not-authorized-img {
    height: 100px;
    width: 100%;
  }
}
