.lHeaderSection {
  display: flex;
  justify-content: center;
  padding: 0px 0px 10px 0px;
}

.lHeaderImg {
  width: 100px;
  background-color: transparent !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
}

.lHeaderTitle {
  margin: 1rem 0 0 0;
  font-size: 1em;
  font-weight: 330;
}

.lHeaderSubtitle {
  font-weight: 300;
  font-size: 1em;
  align-self: flex-start;
  color: rgba(255, 255, 255, 0.769);
}

@media screen and (max-width: 900px) {
  .lHeaderSection {
    width: auto;
  }
}

@media screen and (max-width: 570px) {
  .lHeaderSection {
    width: 100vw;
  }
}
