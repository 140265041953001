.support-container {
  display: flex;
  margin-top: 7px;
  margin-right: 15px;
}

@media screen and (max-width: 900px) {
  .support-container {
    margin-top: 4px;
  }
}

@media screen and (max-width: 570px) {

  .support-container {
    margin-top: 9px;
  }
}