.my-apps-wrapper {
    height: 100%;
    width: 100%;
    padding: 0 1rem;
}

.my-apps-container {
    border-radius: 5px;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    overflow: hidden;
}

.my-apps-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    height: 70px;
}

.my-apps-list {
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    gap: 20px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.my-apps-list::-webkit-scrollbar {
    display: none;
}

.my-apps-list-item {
    margin: 0rem;
    display: flex;
    justify-content: center;
}

.new-app-button {
    height: 40px !important;
    margin-top: 10px !important;
    margin-right: 0 !important;
}

.list-empty-apps-wrapper {
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.list-empty-apps-message {
    font-size: 20px;
    text-align: center;
    color: black;
    margin: 10px 0 !important;
}

.new-app-button {
    font-size: 16px !important;
    height: 32px !important;
    width: 150px !important;
}

@media screen and (max-width: 900px) {

    .my-apps-wrapper {
        padding: 0;
    }

    .my-apps-container {
        padding: 0;
    }

    .my-apps-header {
        padding: 1rem;
    }

    .my-apps-header {
        height: 55px;
    }

    .my-apps-list {
        height: calc(100% - 55px);
        justify-content: center;
    }

    .list-empty-apps-wrapper {
        height: calc(100% - 55px);
    }

}

@media screen and (max-width: 570px) {

    .my-apps-container {
        padding: 0;
    }

    .my-apps-list {
        height: calc(100% - 55px);
        justify-content: center;
        gap: 15px;
    }
}