  .text-area-input {
    width: 100% !important;
    height: 35px !important;
    margin-top: 4px !important;
    background-color: rgb(145, 140, 140) !important;
    color: white !important;
  }

  .edit-ticket-wrapper {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }

  .operator {
    margin: 0px !important;
    color: rgb(48, 47, 47);
    font-size: 14px !important;
  }

  .edit-container {
    padding-top: 5px !important;
    display: flex;
    flex-direction: row;
    height: auto;
    align-items: center;
    width: 100%;
    gap: 20px;
  }

  .input-form {
    background-color: rgb(145, 140, 140) !important;
    color: white !important;
    width: 100% !important;
    margin-top: 4px !important;
  }

  .edit-container-left {
    flex-grow: 1;
    height: 60px;
    width: 50%;
    gap: 10px !important;
  }

  .edit-container-right {
    flex-grow: 1;
    width: 50%;
    height: 60px;
  }

  .edit-ticket-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  .edit-action-btn {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }

  .top-adjust {
    margin-top: -5px;
  }